import React, { Component, useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import socketIOClient from 'socket.io-client';

import * as RealtimeConnection from "../utils/RealtimeConnection";

// Dispatchers
import mapDispatchToProps from '../redux/dispatchers';
import URLs from '../utils/urls';

// Components
import Header from './BoardElements/Header';
import PlayersBar from './BoardElements/PlayersBar';
import EventsBar from './BoardElements/EventsBar';

// Images
import { Board, Board2X } from 'images';

//Pages
import ArrowView from './BoardPages/ArrowView';
import DevView from './BoardPages/DevView';
import EventView from './BoardPages/EventView';
import PlayerProfile from './BoardPages/PlayerProfile';
// CSS
import '../css/flyingObjects.css'
import SelectionLogView from './BoardPages/SelectionLogView';


const BoardPage = (props) => {
	const dispatch = useDispatch()

	const [canEdit, setCanEdit] = useState(false)
	const [socket, setSocket] = useState(undefined)

	const [savePlayerData, setSavePlayerData] = useState(false)
	const [playersData, setPlayersData] = useState([])

	useEffect(() => {
		setPlayersData(props.players)
	}, [props.players])

	useEffect(() => {
		if (playersData.length > 0 && savePlayerData) {
			setSavePlayerData(false)
			props.restoreCharacters(playersData);
		}
	}, [playersData, savePlayerData])

	useEffect(() => {
		setCanEdit(props.canEdit)
		props.restoreLocalization(props.localizations)

		if (socket === undefined) {
			const socketConnection = socketIOClient(URLs.socketEndpoint);
			setSocket(socketConnection)
			window.socket = socketConnection
		}
	}, [])

	useEffect(() => {
		if (socket) {
			socket.on('connect', (data) => {
				console.log('Connected To WS');

				socket.emit('user-data', {
					user_id: window.playerId,
					team_id: window.teamId,
					session_id: window.sessionId,
					token: window.token
				});
			});

			socket.on('new-round', () => {
				props.updateRoundNumber()
				props.purchaseCourseStatus(true)
				props.updateCurrentEvent({})
				props.updateEventView('')
				props.updateEventCards([])
				props.selectedCard(undefined)
				props.selectEventOption({})
			})

			socket.on('restore-session', (data) => {
				props.restoreSession(data.state);
				props.restoreCharacters(data.characters);
				props.restoreSessionData(data.session);
				props.restoreRoundNumber(data.roundNumber);

				if (data.selectedCourses) {
					props.restoreSelectedCourses(data.selectedCourses);
					if (data.selectedCourses[data.roundNumber] !== undefined) {
						props.purchaseCourseStatus(false)
					} else {
						props.purchaseCourseStatus(true)
					}
				}
				if (data.selectedEvents) {
					if (data.selectedEvents[`${data.roundNumber}`] !== undefined) {
						const eventsLength = data.selectedEvents[`${data.roundNumber}`].length
						if (eventsLength > 0) {
							let lastEvent = data.selectedEvents[`${data.roundNumber}`][(eventsLength - 1)].event
							props.UpdateLastEventId(lastEvent.id)
						}
					}
				}

				if (data.waitingSelectedEvents) {
					if (data.waitingSelectedEvents.selectedOption) {
						props.selectEventOption(data.waitingSelectedEvents.selectedOption)
					}
					if (data.waitingSelectedEvents.cards) {
						props.updateEventCards(data.waitingSelectedEvents.cards);
					}
					if (data.waitingSelectedEvents.card) {
						props.selectedCard(data.waitingSelectedEvents.card)
					}
					if (data.waitingSelectedEvents.view) {
						props.updateEventView(data.waitingSelectedEvents.view)
					}
				}
			});

			socket.on('add-character', (data) => {
				props.appendCharacter(data.category, data.cardIndex, {
					id: data.id,
					name: data.name,
					image: data.image,
					qty: data.qty
				});
			});

			socket.on('remove-character', (data) => {
				props.removeCharacter(data.category, data.cardIndex, {
					id: data.id,
					name: data.name,
					image: data.image,
					qty: data.qty
				});
			});

			socket.on('decrease-character', (data) => {
				props.decreaseCharacter({
					id: data.id
				});
			})

			socket.on('increase-character', (data) => {
				props.increaseCharacter({
					id: data.id
				});
			})

			socket.on('update-session', (data) => {
				props.updateSession(data);
			});

			socket.on('update-character', (data) => {
				setTimeout(() => {
					props.updateCharacter(data);
				}, 1000)
			});

			socket.on("files-list", data => {
				props.updateFiles(data);
			});

			socket.on("add-file", data => {
				if (parseInt(data.teamId) === parseInt(window.teamId) || parseInt(data.teamId) === 0) {
					props.addFile(data);
				}
			});

			socket.on("remove-file", data => {
				if (parseInt(data.teamId) === parseInt(window.teamId) || parseInt(data.teamId) === 0) {
					props.removeFile(data._id);
				}
			});

			socket.on("change-team", data => {
				props.changeTeamName(data.value);
			})

			socket.on("select-player-project", data => {
				props.selectCourse(data.characterId, data.course)
			})

			socket.on("deselect-player-project", data => {
				props.deSelectCourse(data.characterId, data.course)
			})

			socket.on('confirm-courses', data => {
				props.coursesSubmitted(data.courses)
				props.purchaseCourseStatus(data.status)
				confirmCoursesCalculation(data)
			})

			socket.on("select-event-option", data => {
				props.selectEventOption(data.option)
			})

			socket.on('select-event-view', data => {
				props.updateEventView(data.view)
			})

			socket.on('event-card-select', data => {
				props.selectedCard(data.selectedCardIndex)
			})

			socket.on('event-card-values', data => {
				props.updateEventCards(data);
			})

			socket.on('restore-event-card-appearance-state', data => {
				props.restoreCardEventStatus(data)
			})
			socket.on('event-card-appearance-status', data => {
				props.updateCardEventStatus(data)

				if (data.type === "showResult" && !data.status) {
					props.updateCardEventStatus({ type: "showCardResult", status: false })
				}
			})

			socket.on('events-submitted', data => {

				let sessionTime = 0 //props.session.time
				let sessionMoney = 0 //props.session.amount

				let sessionTeam = 0;
				let sessionTeamLeader = 0;
				let sessionOrganization = 0;

				let charactersCost = {}
				if (data.selectedCard === "sad") {
					data.selectedOption.FailureSystemImpact.forEach(impact => {
						sessionTeam += impact.team;
						sessionTeamLeader += impact.teamLeader;
						sessionOrganization += impact.organization;
					});

					data.selectedOption.FailureImpact.forEach(impact => {
						if (charactersCost[impact.Character.key] === undefined) {
							charactersCost[impact.Character.key] = { time: 0, amount: 0, skills: 0, motivation: 0, risk: 0 }
						}

						sessionTime -= impact.time;
						sessionMoney -= impact.money;

						charactersCost[impact.Character.key].time += impact.time
						charactersCost[impact.Character.key].amount += impact.money
						charactersCost[impact.Character.key].skills += impact.skills
						charactersCost[impact.Character.key].motivation += impact.motivation
						charactersCost[impact.Character.key].risk += impact.retentionRisk
					})
				} else if (data.selectedCard === "happy") {
					data.selectedOption.SuccessSystemImpact.forEach(impact => {
						sessionTeam += impact.team;
						sessionTeamLeader += impact.teamLeader;
						sessionOrganization += impact.organization;
					});

					data.selectedOption.SuccessImpact.forEach(impact => {
						if (charactersCost[impact.Character.key] === undefined) {
							charactersCost[impact.Character.key] = { time: 0, amount: 0, skills: 0, motivation: 0, risk: 0 }
						}

						sessionTime -= impact.time;
						sessionMoney -= impact.money;

						charactersCost[impact.Character.key].time += impact.time
						charactersCost[impact.Character.key].amount += impact.money
						charactersCost[impact.Character.key].skills += impact.skills
						charactersCost[impact.Character.key].motivation += impact.motivation
						charactersCost[impact.Character.key].risk += impact.retentionRisk
					})
				}

				setPlayersData( prevState => {
					return [
						...prevState.map( character => {
							if (charactersCost[character.id]) {
								character.time += charactersCost[character.id].time;
								character.amount += charactersCost[character.id].amount;
								character.skills += charactersCost[character.id].skills;
								character.motivation += charactersCost[character.id].motivation;
								character.risk += charactersCost[character.id].risk;

								if (charactersCost[character.id].risk !== 0) {
									if (character.risk < 1) {
										character.risk = 1
									} else if (character.risk > 5) {
										character.risk = 5
									}
								}

								if (character.time < 0) {
									character.time = 0
								}
							}

							return character
						})
					]
				})

				props.updateSession_2({ type: 'TIME', value: sessionTime });
				props.updateSession_2({ type: 'AMOUNT', value: sessionMoney });
				props.updateSession_2({ type: 'TEAM', value: sessionTeam });
				props.updateSession_2({ type: 'TEAMLEAD', value: sessionTeamLeader });
				props.updateSession_2({ type: 'ORGANIZATION', value: sessionOrganization });

				setSavePlayerData(true)

				props.updateCardEventStatus({ type: "showCardResult", status: true })

				dispatch({ type: "IMPACT_UPDATE_SESSION_CHARACTER", data: charactersCost })

				dispatch({ type: "IMPACT_UPDATE_HEADER_VALUE", fieldType: "team", value: sessionTeam })
				dispatch({ type: "IMPACT_UPDATE_HEADER_VALUE", fieldType: "teamLeader", value: sessionTeamLeader })
				dispatch({ type: "IMPACT_UPDATE_HEADER_VALUE", fieldType: "organization", value: sessionOrganization })
			})

			socket.on('session-status-changed', data => {
				checkSessionStatus(data.status)
			})

			socket.on("update-white-character-status", data => {
				props.updateWhiteCharacterStatus(data.status)
			})

			socket.on("update-team-white-character", data => {
				props.updateWhiteCharacterData(data.character)
			})

			socket.on("add-timer", data => {
				props.updateCounter(data.data);
			})

			socket.on("apply-default-values", data => {
				dispatch({ type: "APPLY_STARTING_POSITION", data: data })
			})

			socket.on("remove-timer", data => {
				props.updateCounter({
					duration: 0,
					createdAt: 0
				});
			})
		}
	}, [socket])

	const confirmCoursesCalculation = (data) => {
		let charactersCost = {}

		let time = 0;
		let money = 0;

		const playersIds = Object.keys(data.courses)
		if (playersIds.length > 0) {
			playersIds.forEach(playerId => {
				data.courses[playerId].forEach(course => {
					time += course.SimtalentCourseImpacts.time;
					money += course.SimtalentCourseImpacts.money;

					if (charactersCost[playerId] === undefined) {
						charactersCost[playerId] = {
							time: 0,
							amount: 0,
							skills: 0,
							motivation: 0,
							risk: 0
						}
					}
					charactersCost[playerId].time += course.SimtalentCourseImpacts.time
					charactersCost[playerId].amount += course.SimtalentCourseImpacts.money
					charactersCost[playerId].skills += course.SimtalentCourseImpacts.skills
					charactersCost[playerId].motivation += course.SimtalentCourseImpacts.motivation
					charactersCost[playerId].risk += course.SimtalentCourseImpacts.retentionRisk
				})
			})
		}

		props.updateSessionTime({ type: 'Time', value: time })
		props.updateSessionTime({ type: 'AMOUNT', value: money })

		props.updateCharacterValues(charactersCost)
		dispatch({ type: "IMPACT_UPDATE_SESSION_CHARACTER", data: charactersCost })
	}

	const checkSessionStatus = (status) => {
		switch (parseInt(status)) {
			case 2: // Session Running
				setCanEdit(true)
				break;
			case 3: // Session Paused
				setCanEdit(false)
				break;
			default:
				break;
		}
	}

	const increaseCharacter = (character) => {
		props.increaseCharacter(character);

		let characterIndex = 0
		props.players.forEach((player, index) => {
			if (player.id === character.id) {
				characterIndex = index
			}
		})
		RealtimeConnection.increaseCharacter({
			id: character.id,
			index: characterIndex,
			qty: 1
		})
	}

	const openLog = () => {
		props.openSelectionLog()
	}

	const activeProfile = props.session.playerProfile ? "activeProfile" : ""
	return (
		<DndProvider backend={HTML5Backend}>
			<Header
				teamName={props.teamName}
				canEdit={canEdit}
				increaseCharacterAction={ increaseCharacter }
				openLog={ openLog }
			/>
			<PlayersBar socket={ socket } canEdit={canEdit} partnerLogo={props.partnerLogo} />
			<EventsBar languages={props.languages} canEdit={canEdit} />
			<div className={`boardContainer ${(localStorage.isRTL === "1") ? "rtl" : ""}`}>
				<div className={`boardPageWrapper ${activeProfile}`}>
					{!props.session.playerProfile && (
						<div className="main-image-wrapper">
							<source srcSet={`${Board2X} 2x`} />
							<img src={Board} alt="Board" />
						</div>
					)}

					{!props.session.development && !props.session.selectionLog && !props.session.event && !props.session.playerProfile && <ArrowView canEdit={canEdit} />}
					{
						(props.session.selectionLog) ?
							<SelectionLogView />
							:
							<React.Fragment>
								{props.session.development && !props.session.playerProfile && <DevView />}
							</React.Fragment>
					}
					{props.session.event && !props.session.playerProfile && <EventView />}
					{props.session.selectedPlayer.id && <PlayerProfile />}
				</div>
			</div>
		</DndProvider>
	);
}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		players: state.startingKit.players,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardPage);
